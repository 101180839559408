<template>
  <div class="course-detail">
    <div class="header">
      <div class="container-header">
        <div class="course-item">
          <div class="course-content">
            <div class="course-img">
              <img
                :src="courseInfo.logo2"
                alt=""
                style="width: 240px; height: 170px"
              />
            </div>
            <div class="course-content-item">
              <div class="course-name">
                {{ courseInfo.name }}
              </div>
              <div class="course-descr">
                {{ courseInfo.descr }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 课程列表 -->
    <div class="container">
      <div class="row-name">班级列表</div>
      <div class="line"></div>
      <div class="class-list" v-if="classList.length !== 0">
        <div
          class="class-item"
          @click="goDetail(item.id)"
          v-for="(item, index) in classList"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <div v-else>
        <el-empty description="暂无数据"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { getCourseDetail, getClass } from "@/api/course";
export default {
  data() {
    return {
      classType: "",
      id: 0,
      courseInfo: {},
      classList: [],
      limt: 10,
      page: 1,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getCourseDetail(this.id);
    this.getClassByContent(this.id);
    localStorage.setItem("page", "/courseDetail");
    localStorage.setItem("id", this.id);
  },
  methods: {
    //查询课程详情
    getCourseDetail(id) {
      getCourseDetail({ id: id }).then((res) => {
        this.courseInfo = res.data;
      });
    },
    //根据课程查询班级
    getClassByContent(id) {
      getClass(id).then((res) => {
        this.classList = res.data;
      });
    },
    //点击下一页
    nextClick() {
      this.page = this.page + 1;
    },
    //点击上一页
    prevClick() {
      this.page = this.page - 1;
    },
    //改变页数
    currentChange(page) {
      this.page = page;
      this.getClassByContent(this.id);
    },
    //跳转到班级详情
    goDetail(id) {
      this.$router.push({
        path: "/classDetail",
        query: { id: id, courseId: this.id },
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.el-pagination {
  text-align: center;
}
.course-detail {
  .row-name {
    font-size: 20px;
    margin-top: 30px;
    font-weight: 400;
  }

  .line {
    height: 5px;
    width: 60px;
    margin: 4px 0 30px 0;
    background-color: #4394ff;
    border-radius: 5px;
  }
  .class-item {
    border-bottom: 1px solid #eeeeee;
    padding: 20px;
    font-size: 24px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .header {
    width: 100%;
    background-color: #323368;
    .container-header {
      width: 1200px;
      margin: 0 auto;
      padding: 60px 0 80px 0;
      display: flex;
      .course-item {
        // border: 1px solid #EEEEEE;
        width: 100%;
        padding: 20px;
        margin-bottom: 20px;
        .course-type-name {
          margin-bottom: 15px;
        }
        .course-content {
          display: flex;
          .course-content-item {
            margin-left: 20px;
            flex: 1;
            .course-name {
              margin-bottom: 13px;
              font-size: 18px;
              color: #ffffff;
            }
            .course-descr {
              color: #a7a7a7;
            }
          }
        }
      }
    }
  }
}
.course-img{
  width:224px; 
  height:160px;
}
</style>